body {
  /*--colors-primary: #ff8200;
    --colors-primary-hover: #F39D0A;
    --colors-separator-mobile: #F4BA46;

    --colors-bg-button:#011842;
    --colors-bg-button-hover:#011842; 00859B - 0095b3
    --colors-bg-plain-card: #F2F2F2;
    --colors-bg-plain-card-old: #C9EAF1;*/

  --colors-primary: #011842;
  --colors-primary-hover: #f39d0a;
  --colors-separator-mobile: #f4ba46;
  --colors-blue-secondary: #00809a;

  --colors-bg-button: #ff8200;
  --colors-bg-button-hover: #011842;
  --colors-bg-plain-card: #f2f2f2;
  --colors-bg-plain-card-old: #c9eaf1;

  --colors-text-dim: #818181;
  --colors-text-normal: #464646;
}
